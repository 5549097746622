import React, { Component } from 'react';
import { connect } from 'react-redux';
import { array, bool } from 'prop-types';
import { Redirect } from 'react-router-dom';
import { isEmpty } from 'lodash';
import './CPD.scss';
import M from 'materialize-css';
import CourseActions, {
   isFetchingAvailableCourses,
} from 'pgdb-data-layer/lib/Redux/CoursesRedux';
import ResultActions, {
   isFetchingResult,
} from 'pgdb-data-layer/lib/Redux/ResultRedux';
import UserActions, {
   isFetchingUserInfo,
} from 'pgdb-data-layer/lib/Redux/UserInfoRedux';
import {
   EXEMPT_LICENCE_TYPES,
   TRAINEE_LICENCE_TYPES,
} from 'pgdb-data-layer/lib/Constants/LicenceTypes';
import SupervisionActions, {
   getMySupervisors,
   isFetchingSupervision,
} from 'pgdb-data-layer/lib/Redux/SupervisionRedux';
import * as COURSE from 'pgdb-data-layer/lib/Constants/Course';
import * as CPD_HELPERS from 'pgdb-data-layer/lib/Helpers/CPD';
import Navigator from '../../Navigation/Navigator';
import Icon from '../../Components/Icon';
import ExternalLink from '../../Components/ExternalLink';
import { getToken } from '../../Session/SessionRedux';
import Loading from '../../Components/Loading';

const NEXT_WIZARD_PAGE = '/renew-supervisors';

class CPD extends Component {
   constructor(props) {
      super(props);
      this.state = {
         isUserLicenced: false,
         currentYearDescription: '',
         hasFetched: false,
      };
   }

   componentDidMount() {
      const { dispatch, token, isApplyingForLicense } = this.props;
      dispatch(UserActions.getUserContactInfoRequest(token));
      if (isApplyingForLicense) {
         dispatch(
            SupervisionActions.getUserApplicationsRegistrationLicenceRequest(
               token
            )
         );
      } else {
         dispatch(SupervisionActions.getUserApplicationsRenewalRequest(token));
      }
   }

   componentDidUpdate(prevProps) {
      const {
         userContactData,
         userResult,
         userCourses,
         dispatch,
         urlHowIO,
         token,
      } = this.props;
      if (isEmpty(userContactData)) {
         dispatch(UserActions.getUserContactInfoRequest(token));
      } else if (userContactData !== prevProps.userContactData) {
         this.calculateUserRegistration();
      } else if (userResult !== prevProps.userResult) {
         this.calculateUserCPD();
      } else if (userCourses !== prevProps.userCourses) {
         this.calculateUserCourses();
      } else if (urlHowIO && urlHowIO !== prevProps.urlHowIO) {
         window.open(urlHowIO);
         dispatch(CourseActions.getHowIoUrlSsoReset());
      }
   }

   skipCPD = () => {
      M.Toast.dismissAll();
      M.toast({
         html: 'Skipping CPD step as CPD does not apply to you',
         classes: 'success',
      });

      Navigator.to('dashboard');
   };

   calculateUserRegistration = () => {
      const { userContactData, dispatch, token } = this.props;
      this.setState({ hasFetched: true });
      if (CPD_HELPERS.checkUserRegistration(userContactData)) {
         // only proceed with getting user results if they have a registration/licence
         this.setState({ isUserLicenced: true });
         dispatch(
            ResultActions.getResultsByUserIdRequest(
               token,
               COURSE.TYPE_MANDATORY_CPD
            )
         );
      }
   };

   calculateUserCPD = () => {
      const { userResult, dispatch, token } = this.props;
      if (!userResult) return;

      // current year is not actually the current year but rather the CPD looks for courses a year - 1 back
      // only if the user satisfies all conditions for last year that it actually clears them for this year
      const currentYear = userResult.maxLicenceYear;
      const previousYear = currentYear ? +currentYear - 1 : undefined;

      let currentYearDescription = '';
      let nextYearDescription = '';
      let nextYear = '';
      if (!isNaN(currentYear)) {
         nextYear = (currentYear + 1).toString();
         currentYearDescription = `${currentYear}-${nextYear.slice(2, 4)}`;
         nextYearDescription = `${nextYear}-${(+nextYear + 1)
            .toString()
            .slice(2, 4)}`;
      }
      // we only want the courses based on the current year specified in the user results returned
      dispatch(
         CourseActions.getAvailableCoursesRequest(
            token,
            currentYear,
            COURSE.TYPE_MANDATORY_CPD
         )
      );
      // endpoint will retrieve all results pass or fail. We only need to be concerned with pass
      const currentYearPassed = CPD_HELPERS.calculateResultForYear(
         userResult.results,
         previousYear
      );
      const nextYearPassed = CPD_HELPERS.calculateResultForYear(
         userResult.results,
         currentYear
      );

      this.setState({
         currentYearPassed,
         nextYearPassed,
         previousYear,
         currentYear,
         nextYear,
         currentYearDescription,
         nextYearDescription,
         userResult,
      });
   };

   calculateUserCourses = () => {
      const { userCourses } = this.props;
      if (userCourses === null && userCourses.data === null) {
         // no courses are available for the provided year
         return;
      }

      this.setState({ userCourses });
   };

   isOnlyExemptOrTrainee = () => {
      const { userApplications } = this.props;
      const applications = userApplications || [];

      let countRenewedExemptOrTrainee = 0;
      let countRenewed = 0;
      applications.forEach(a => {
         if (a.licenceStatus === 'Renewed' || a.licenceStatus === 'Paid') {
            countRenewed += 1;

            if (
               EXEMPT_LICENCE_TYPES[a.licenceTypeCode.trim()] ||
               TRAINEE_LICENCE_TYPES[a.licenceTypeCode.trim()]
            ) {
               countRenewedExemptOrTrainee += 1;
            }
         }
      });
      return countRenewed > 0 && countRenewed === countRenewedExemptOrTrainee;
   };

   render() {
      const {
         isApplyingForLicense,
         renew,
         footer,
         isLoading,
         dispatch,
         token,
      } = this.props;
      const {
         isUserLicenced,
         currentYearPassed,
         currentYearDescription,
         previousYear,
         currentYear,
         nextYear,
         userResult,
         userCourses,
         nextYearPassed,
         nextYearDescription,
         hasFetched,
      } = this.state;

      const shouldSkipCpd =
         hasFetched &&
         !isLoading &&
         (this.isOnlyExemptOrTrainee() || !isUserLicenced);
      if (renew && shouldSkipCpd) {
         M.Toast.dismissAll();
         M.toast({
            html: 'Skipping CPD step as CPD does not apply to you',
            classes: 'success',
         });

         return (
            <Redirect
               to={{
                  pathname: NEXT_WIZARD_PAGE,
                  state: { cpdSkipped: true },
               }}
            />
         );
      }

      const callGetURLHowIO = course =>
         dispatch(CourseActions.getHowIoUrlSsoRequest(token, course));

      const renderNexYearNotPassed = () => {
         return (
            <>
               <div className="row">
                  <ExternalLink className={'columnheader col l12 s12'}>
                     {nextYearDescription} Licence
                  </ExternalLink>
               </div>
               <div className="row">
                  <p id="cpd-sub-description" className="col l12 s12">
                     Do you want to complete the {currentYear} CPD course so you
                     can renew your licence in {nextYear}?
                  </p>
               </div>
               <div className="row valign-wrapper">
                  <Icon className="round large red-text" iconName="close" />
                  <p className="red-text mr-1">
                     <b>{currentYear} course to be completed.</b>
                  </p>
                  <button
                     id={`how-io-link`}
                     className="btn-small"
                     onClick={() =>
                        callGetURLHowIO(
                           userCourses.nextYearsCourses.find(
                              x => x.licenceYearId === currentYear
                           ).courseNumber
                        )
                     }
                  >
                     Complete here
                  </button>
               </div>
            </>
         );
      };

      const renderNextYearPassed = () => {
         return (
            <>
               <div className="row">
                  <ExternalLink className={'columnheader col l12 s12'}>
                     {nextYearDescription} Licence
                  </ExternalLink>
               </div>
               <div className="row valign-wrapper">
                  <Icon
                     className="round large light-green-text"
                     iconName="check"
                  />
                  <p className="light-green-text">
                     <b>{currentYear} course has been completed.</b>
                  </p>
               </div>
            </>
         );
      };

      return (
         <main>
            <div className="section" id="cpd-section">
               <Loading isLoading={isLoading}>
                  {!isUserLicenced && (
                     <div className="container">
                        <div className="row">
                           <div className="col l8 offset-l1">
                              <h3
                                 className="columnheader"
                                 id="cpd-section-header"
                              >
                                 No Registration Found
                              </h3>
                              <Icon
                                 className="round large light-yellow-text"
                                 iconName="error"
                              />
                              <p id="cpd-sub-description">
                                 CPD doesn&apos;t apply to you at this point, as
                                 it&apos;s only a requirement if you are registered. However, if you would like
                                 to complete this course anyway,{' '}
                                 <ExternalLink href="https://www.pgdb.co.nz/trade/cpd">
                                    please click here to book it.
                                 </ExternalLink>
                              </p>
                           </div>
                           {isApplyingForLicense && footer}
                        </div>
                     </div>
                  )}
                  {isUserLicenced && (
                     <div className="container">
                        <div className="row">
                           <div className="col l8 offset-l1">
                              {!renew && (
                                 <>
                                    <h3
                                       className="columnheader"
                                       id="cpd-section-header"
                                    >
                                       My CPD
                                    </h3>
                                    <p id="cpd-section-sub-header">
                                       My Continuing Professional Development
                                    </p>
                                    <p id="cpd-sub-description">
                                       If you are a registered person,
                                       completing CPD is a mandatory condition
                                       to relicence each year.
                                    </p>
                                 </>
                              )}
                              {currentYearPassed && currentYearDescription && (
                                 <>
                                    <div className="row">
                                       <ExternalLink
                                          className={'columnheader col l12 s12'}
                                       >
                                          {currentYearDescription} Licence
                                       </ExternalLink>
                                    </div>
                                    <div className="row valign-wrapper">
                                       <Icon
                                          className="round large light-green-text"
                                          iconName="check"
                                       />
                                       <p className="light-green-text">
                                          <b>
                                             {previousYear} course has been
                                             completed.
                                          </b>
                                       </p>
                                    </div>
                                    {!nextYearPassed &&
                                       userCourses &&
                                       userCourses.nextYearsCourses[0] &&
                                       renderNexYearNotPassed()}
                                    {nextYearPassed && renderNextYearPassed()}
                                 </>
                              )}
                              {userCourses &&
                                 ((!userResult && !userResult.results) ||
                                    !currentYearPassed) && (
                                    <>
                                       <div className="row">
                                          <ExternalLink
                                             className={
                                                'columnheader col l12 s12'
                                             }
                                          >
                                             {currentYearDescription} Licence
                                          </ExternalLink>
                                       </div>
                                       <div className="row">
                                          <p
                                             id="cpd-sub-description"
                                             className="col l12 s12"
                                          >
                                             Have I completed the CPD
                                             requirement?
                                          </p>
                                       </div>
                                       <div className="row valign-wrapper">
                                          <Icon
                                             className="round large red-text"
                                             iconName="close"
                                          />
                                          <p className="red-text mr-1">
                                             <b>
                                                {previousYear} course to be
                                                completed.
                                             </b>
                                          </p>

                                          <button
                                             id={`how-io-link`}
                                             className="btn-small"
                                             onClick={() =>
                                                callGetURLHowIO(
                                                   userCourses.currentYearsCourses.find(
                                                      x =>
                                                         x.licenceYearId ===
                                                         previousYear
                                                   ).courseNumber
                                                )
                                             }
                                          >
                                             Complete here
                                          </button>
                                       </div>
                                       <div className="row">
                                          <p
                                             id="cpd-sub-description"
                                             className="col l12 s12"
                                          >
                                             Contact The Board at{' '}
                                             <a
                                                className="activeblue-text"
                                                href="mailto:cpd@pgdb.co.nz"
                                             >
                                                cpd@pgdb.co.nz
                                             </a>{' '}
                                             to get a copy of the questionnaire.
                                          </p>
                                       </div>
                                       {!nextYearPassed &&
                                          userCourses.nextYearsCourses &&
                                          userCourses.nextYearsCourses[0] &&
                                          renderNexYearNotPassed()}
                                       {nextYearPassed &&
                                          renderNextYearPassed()}
                                    </>
                                 )}
                           </div>
                           {(renew || isApplyingForLicense) && footer}
                        </div>
                     </div>
                  )}
               </Loading>
            </div>
         </main>
      );
   }
}

CPD.propTypes = {
   isLoading: bool,
   userApplications: array,
};

CPD.defaultProps = {
   isLoading: true,
   userApplications: [],
};

const mapStateToProps = state => {
   const userContactInfoBusy = isFetchingUserInfo('getUserContactInfo', state);
   const getResultsByUserIdBusy = isFetchingResult('getResultsByUserId', state);
   const isFetchingApplicationsBusy = isFetchingSupervision(
      'getUserApplicationsRenewal',
      state
   );
   const getUserCoursesBusy = isFetchingAvailableCourses(state);

   return {
      userContactData: state.user.contactData,
      userResult: state.result.data,
      urlHowIO: state.course.urlHowIO,
      userCourses: state.course.availableCourses,
      userApplications: getMySupervisors(state),
      isLoading:
         userContactInfoBusy ||
         getResultsByUserIdBusy ||
         getUserCoursesBusy ||
         isFetchingApplicationsBusy,
      token: getToken(state),
   };
};

export default connect(mapStateToProps)(CPD);
