import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import './Registration.scss';
import RegistrationActions, {
   isFetchingRegistration,
} from 'pgdb-data-layer/lib/Redux/RegistrationRedux';
import Modal from 'react-modal';
import * as REGISTRATION from 'pgdb-data-layer/lib/Constants/Registration';
import * as STATUS from 'pgdb-data-layer/lib/Constants/Contact';
import LicenceActions, {
   isFetchingLicence,
} from 'pgdb-data-layer/lib/Redux/LicenceRedux';
import UserActions, {
   isFetchingUserInfo,
} from 'pgdb-data-layer/lib/Redux/UserInfoRedux';
import ApplicationActions, {
   isFetchingApplicationSuccess,
} from 'pgdb-data-layer/lib/Redux/ApplicationsRedux';
import TradeSection from './TradeSection';
import { getToken } from '../../../Session/SessionRedux';
import Loading from '../../../Components/Loading';
import ExternalLink from '../../../Components/ExternalLink';
import Icon from '../../../Components/Icon';
import ApplicationStatus from './Components/ApplicationStatus';
import ApplyRegistrationSection from './Components/ApplyRegistrationSection';

const Registration = ({
   getUserRegistrationRequest,
   isFetchingRegistration,
   isFetchingLicence,
   getLicence,
   token,
   userRegistration,
   userContactData,
   userLicence,
   submitRegistrationReset,
   isFetchingApplicantDetails,
   getUserContactInfoRequest,
   isDeleteSuccess,
}) => {
   const [hasDispatched, setHasDispatched] = useState(false);
   const [isOpenInfo, setIsOpenInfo] = useState(false);
   const [canRegister, setCanRegister] = useState(undefined);
   const userCurrentStatus = userContactData.contactStatusCode;
   const isLoading =
      !hasDispatched ||
      isFetchingRegistration ||
      isFetchingLicence ||
      isFetchingApplicantDetails ||
      canRegister === undefined;
   const [isFullyCertified, setIsFullCertified] = useState(false);
   const [hasReloaded, setHasReloaded] = useState(false);

   useEffect(() => {
      getUserRegistrationRequest(token);
      getLicence(token);
      setHasDispatched(true);
      if (userCurrentStatus === undefined) {
         getUserContactInfoRequest(token);
      }
   }, [token]);

   useEffect(() => {
      submitRegistrationReset();
   }, []);

   useEffect(() => {
      setHasReloaded(false);
   }, [isDeleteSuccess]);

   const reloadRegistrationPage = () => {
      if (!hasReloaded) {
         getUserRegistrationRequest(token);
         setHasReloaded(true);
      }
   };

   // check if user is certifying in all trades and remove the top section if they are.
   useEffect(() => {
      if (userRegistration && userRegistration.registration) {
         if (userRegistration.registration.registrations) {
            const { registrations } = userRegistration.registration;
            const certifyingPlumberMet = registrations.find(
               reg =>
                  reg.registrationTypeCode ===
                  REGISTRATION.CERTIFYING_PLUMBER_CODE
            );
            const certifyingGasfitterMet = registrations.find(
               reg =>
                  reg.registrationTypeCode ===
                  REGISTRATION.CERTIFYING_GASFITTER_CODE
            );
            const certifyingDrainlayerMet = registrations.find(
               reg =>
                  reg.registrationTypeCode ===
                  REGISTRATION.CERTIFYING_DRAINLAYER_CODE
            );
            setIsFullCertified(
               certifyingPlumberMet !== undefined &&
                  certifyingGasfitterMet !== undefined &&
                  certifyingDrainlayerMet !== undefined
            );
         }
      }
      if (userCurrentStatus === undefined) {
         setCanRegister(false);
      } else if (
         userCurrentStatus !== STATUS.CONTACT_STATUS_DECEASED &&
         userCurrentStatus !== STATUS.CONTACT_STATUS_RETIRED
      ) {
         setCanRegister(true);
      } else setCanRegister(false);
   }, [userRegistration, userLicence]);

   return (
      <main>
         <Loading isLoading={isLoading}>
            <div className="section no-pad-bot" id="index-banner">
               <div className="container registration-container">
                  <div className="row no-margin">
                     {!isFullyCertified && canRegister && (
                        <ApplyRegistrationSection />
                     )}
                     <ApplicationStatus
                        onApplicationDeleted={reloadRegistrationPage}
                     />
                     <div className="col m8 offset-l1 s11">
                        <h4
                           className="columnheader"
                           id="registration-section-header"
                        >
                           My Registrations
                        </h4>
                     </div>
                     {!isFullyCertified && (
                        <div className="col m10 offset-l1 s11">
                           <p>Getting your paperwork right!</p>
                           <p>
                              Guidance on these requirements, and overseas or
                              alternative pathways to registration, are part of
                              the relevant application forms{' '}
                              <ExternalLink
                                 className="link"
                                 href="https://www.pgdb.co.nz/apply_for_registration/"
                              >
                                 available here
                              </ExternalLink>
                           </p>
                           <p className="fit-text">
                              Info on how to obtain Fit and Proper documentation{' '}
                              <button
                                 type="button"
                                 onClick={() => setIsOpenInfo(true)}
                                 className="waves-effect waves-light btn-small button-margin"
                              >
                                 CLICK HERE
                              </button>
                           </p>
                        </div>
                     )}
                  </div>
                  <TradeSection
                     disciplineCode={REGISTRATION.DISCIPLINE_CODE_PLUMBER}
                     disciplineDescription={REGISTRATION.DISCIPLINE_DESCRIPTION_PLUMBER}
                     heading='Plumbing'
                  />
                  <TradeSection
                     disciplineCode={REGISTRATION.DISCIPLINE_CODE_GASFITTER}
                     disciplineDescription={REGISTRATION.DISCIPLINE_DESCRIPTION_GASFITTER}
                     heading='Gasfitting'
                  />
                  <TradeSection
                     disciplineCode={REGISTRATION.DISCIPLINE_CODE_DRAINLAYER}
                     disciplineDescription={REGISTRATION.DISCIPLINE_DESCRIPTION_DRAINLAYER}
                     heading='Drainlaying'
                  />
               </div>
            </div>
            <Modal
               isOpen={isOpenInfo}
               onRequestClose={() => {
                  setIsOpenInfo(false);
               }}
               className="generic-modal"
               overlayClassName="generic-modal-overlay"
               appElement={document.getElementById('root')}
            >
               <Icon
                  className="fit-model-icon material-icons right"
                  iconName="close"
                  onClick={() => setIsOpenInfo(false)}
               />
               <br />
               <div className="fit-modal-body">
                  <h4>
                     NZ Ministry of Justice criminal record dated within the
                     last month
                  </h4>
                  <p>
                     You can get this free of charge within 10-20 working days
                     from{' '}
                     <ExternalLink href="https://www.justice.govt.nz/criminal-records/get-your-own/">
                        https://www.justice.govt.nz/criminal-records/get-your-own/
                     </ExternalLink>
                  </p>
                  <p>
                     Or, you can use a company such as CheckPlease (
                     <ExternalLink href="https://checkplease.co.nz">
                        https://checkplease.co.nz
                     </ExternalLink>
                     ) who can get it to you within a few working days for a
                     fee.
                  </p>
                  <p>
                     Note: The record must be dated within the last month, and
                     in original form if you received it by post.
                  </p>
                  <h4>Current overseas police certificates</h4>
                  <p>
                     From any country you have lived in for 12 months or more
                     (whether in one visit or intermittently) in the last 10
                     years.
                  </p>
                  <p>
                     Use NZ Immigration’s tool to find out how to obtain the
                     right Police Certificate from the relevant country{' '}
                     <ExternalLink href="https://www.immigration.govt.nz/new-zealand-visas/apply-for-a-visa/tools-and-information/tools/police-certificates">
                        https://www.immigration.govt.nz/new-zealand-visas/apply-for-a-visa/tools-and-information/tools/police-certificates
                     </ExternalLink>
                  </p>
                  <p>
                     Note: These must be the original documents, and dated
                     within the last six months.
                  </p>
               </div>
            </Modal>
         </Loading>
      </main>
   );
};

const mapStateToProps = state => {
   return {
      userRegistration: state.registration,
      userContactData: state.user.contactData,
      userLicence: state.licence,
      isFetchingRegistration: isFetchingRegistration(
         'getUserRegistration',
         state
      ),
      isFetchingApplicantDetails: isFetchingUserInfo(
         'getUserContactInfo',
         state
      ),
      isFetchingLicence: isFetchingLicence('get', state),
      isDeleteSuccess: isFetchingApplicationSuccess('deleteApplication', state),
      token: getToken(state),
   };
};

const mapDispatchToProps = {
   submitRegistrationReset: ApplicationActions.submitRegistrationReset,
   getUserRegistrationRequest: RegistrationActions.getUserRegistrationRequest,
   getLicence: LicenceActions.licenceRequest,
   getUserContactInfoRequest: UserActions.getUserContactInfoRequest,
};

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(Registration);
