import { pick } from 'lodash';
import Immutable from 'seamless-immutable';

import PublicRegisterActions from 'pgdb-data-layer/lib/Redux/PublicRegisterRedux';
import {
   SEARCH_PLUMBER_LICENCE_TYPES,
   SEARCH_GASFITTER_LICENCE_TYPES,
   SEARCH_DRAINLAYER_LICENCE_TYPES,
   LICENCE_TYPES,
   EMPLOYER_LICENCE_TYPE,
} from 'pgdb-data-layer/lib/Constants/LicenceTypes';
import { DefaultPagingModel } from 'pgdb-data-layer/lib/Helpers/Others/PagedQuery';

export const SEARCH_TYPES = Immutable({
   LICENCE: 'licenceNumber',
   NAME: 'name',
   SUBURB: 'suburb',
   TOWN: 'town',
   POSTCODE: 'postcode',
   LICENCE_TYPE: 'licenceType',
   ADVANCED: 'advanced',
   BASIC: 'basic',
   SEARCH_VALUE: 'searchValue',
});

export const CURRENTLY_LICENSED_ONLY = 'currentlyLicensedOnly';
export const COMBINED_SEARCH_VALIDATION = 'combined';

export const SEARCH_ACTION = PublicRegisterActions.publicRegisterRequest;

const descriptions = {
   [SEARCH_TYPES.LICENCE]: 'Licence Number',
   [SEARCH_TYPES.NAME]: 'Name',
   [SEARCH_TYPES.SUBURB]: 'Suburb',
   [SEARCH_TYPES.TOWN]: 'Town/City',
   [SEARCH_TYPES.POSTCODE]: 'Postcode',
   [SEARCH_TYPES.LICENCE_TYPE]: 'Licence Type',
   [SEARCH_TYPES.ADVANCED]: 'Advanced Search',
};
const resultTypeDescriptions = {
   ...descriptions,
   [SEARCH_TYPES.NAME]: 'First and Last Name',
};

const SEARCH_TYPE_DESCRIPTIONS = Immutable(descriptions);
const SEARCH_RESULT_TYPE_DESCRIPTIONS = Immutable(resultTypeDescriptions);

export const SIMPLE_SEARCH_VALUES = Immutable(
   pick(SEARCH_TYPE_DESCRIPTIONS, [
      SEARCH_TYPES.LICENCE,
      SEARCH_TYPES.NAME,
      SEARCH_TYPES.SUBURB,
      SEARCH_TYPES.TOWN,
      SEARCH_TYPES.POSTCODE,
      SEARCH_TYPES.LICENCE_TYPE,
   ])
);

export const getSearchTypeDescription = searchType =>
   SEARCH_TYPE_DESCRIPTIONS[searchType];

export const getSearchResultTypeDescription = searchType =>
   SEARCH_RESULT_TYPE_DESCRIPTIONS[searchType];

const defaultSearchPlaceholder = 'Please select a Search Category';
const searchPlaceholders = Immutable({
   [SEARCH_TYPES.LICENCE]: 'Enter Number',
   [SEARCH_TYPES.NAME]: 'Enter First and Last Name',
   [SEARCH_TYPES.SUBURB]: 'Enter Suburb',
   [SEARCH_TYPES.TOWN]: 'Enter Town/City',
   [SEARCH_TYPES.POSTCODE]: 'Enter Postcode',
   [SEARCH_TYPES.LICENCE_TYPE]: 'Select Licence Type',
});

export const getInputPlaceholder = placeholderKey =>
   searchPlaceholders[placeholderKey] || defaultSearchPlaceholder;

const inputMaxLengths = Immutable({
   [SEARCH_TYPES.LICENCE]: 6,
   [SEARCH_TYPES.NAME]: 250,
   [SEARCH_TYPES.SUBURB]: 250,
   [SEARCH_TYPES.TOWN]: 250,
   [SEARCH_TYPES.POSTCODE]: 10,
   [SEARCH_TYPES.LICENCE_TYPE]: -1,
});

export const getInputMaxLength = searchType => inputMaxLengths[searchType];

const inputFieldIds = Immutable({
   [SEARCH_TYPES.LICENCE]: 'input-prsearch-licence-number',
   [SEARCH_TYPES.NAME]: 'input-prsearch-name',
   [SEARCH_TYPES.SUBURB]: 'input-prsearch-suburb',
   [SEARCH_TYPES.TOWN]: 'input-prsearch-town',
   [SEARCH_TYPES.POSTCODE]: 'input-prsearch-postcode',
   [SEARCH_TYPES.LICENCE_TYPE]: 'input-prsearch-licence-type',
});

export const getFieldId = searchType => inputFieldIds[searchType];

export const SEARCH_LICENCE_TYPE_OPTIONS = Immutable({
   Plumbing: SEARCH_PLUMBER_LICENCE_TYPES,
   Gasfitting: SEARCH_GASFITTER_LICENCE_TYPES,
   Drainlaying: SEARCH_DRAINLAYER_LICENCE_TYPES,
   Employer: pick(LICENCE_TYPES, [EMPLOYER_LICENCE_TYPE]),
});

export const INITIAL_SEARCH_STATE = Immutable({
   ...DefaultPagingModel,
   [SEARCH_TYPES.LICENCE]: '',
   [SEARCH_TYPES.NAME]: '',
   [SEARCH_TYPES.SUBURB]: '',
   [SEARCH_TYPES.TOWN]: '',
   [SEARCH_TYPES.POSTCODE]: '',
   [SEARCH_TYPES.LICENCE_TYPE]: '',
   [CURRENTLY_LICENSED_ONLY]: true,
});

export const INITIAL_BASIC_SEARCH_STATE = Immutable({
   ...DefaultPagingModel,
   [SEARCH_TYPES.SEARCH_VALUE]: '',
});

export const INITIAL_VALIDATION_STATE = Immutable({
   [SEARCH_TYPES.LICENCE]: '',
   [SEARCH_TYPES.NAME]: '',
   [SEARCH_TYPES.SUBURB]: '',
   [SEARCH_TYPES.TOWN]: '',
   [SEARCH_TYPES.POSTCODE]: '',
   [SEARCH_TYPES.LICENCE_TYPE]: '',
   [CURRENTLY_LICENSED_ONLY]: '',
   [COMBINED_SEARCH_VALIDATION]: '',
});

export const normalizeSearchValues = (searchValues, searchType) => {
   const newValues =
      searchType === SEARCH_TYPES.BASIC
         ? { ...INITIAL_BASIC_SEARCH_STATE }
         : { ...INITIAL_SEARCH_STATE };

   Object.keys(searchValues).forEach(k => {
      const val = searchValues[k];
      newValues[k] = typeof val === 'string' ? (val || '').trim() : val;
   });

   return newValues;
};

export const hasSearchValues = searchValues => {
   const validationKeys = Object.values(SEARCH_TYPES);

   return validationKeys.some(k => searchValues[k] !== INITIAL_SEARCH_STATE[k]);
};

export const hasValidationErrors = validations => {
   const validationKeys = [
      ...Object.values(SEARCH_TYPES),
      COMBINED_SEARCH_VALIDATION,
      CURRENTLY_LICENSED_ONLY,
   ];

   const result = validationKeys.some(
      k => validations[k] !== INITIAL_VALIDATION_STATE[k]
   );
   return result;
};
