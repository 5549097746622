import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { string, object } from 'prop-types';
import moment from 'moment';
import * as REGISTRATION from 'pgdb-data-layer/lib/Constants/Registration';
import * as CONTACT from 'pgdb-data-layer/lib/Constants/Contact';
import M from 'materialize-css';
import ApplicationSection from './ApplicationSection';
import Icon from '../../../Components/Icon';

const TradeSection = ({ heading, disciplineCode, userRegistration, disciplineDescription }) => {
   const [tradeRegistrations, setTradeRegistrations] = useState();

   useEffect(() => {
      if (userRegistration && userRegistration.registration) {
         if (userRegistration.registration.registrations) {
            setTradeRegistrations(
               userRegistration.registration.registrations.filter(
                  reg =>
                     reg.registrationDiscipline === disciplineCode &&
                     (reg.registrationStatusCode ===
                        REGISTRATION.STATUS_ACTIVE ||
                        reg.registrationStatusCode ===
                           CONTACT.REGISTRATION_SUPERSEDED_STATUS_CODE ||
                        reg.registrationStatusCode ===
                           CONTACT.REGISTRATION_SUSPENDED_STATUS_CODE ||
                        reg.registrationStatusCode ===
                           CONTACT.REGISTRATION_CANCELLED_STATUS_CODE)
               )
            );
         }
      }
   }, [userRegistration]);

   useEffect(() => {
      const elem = document.querySelectorAll('.collapsible');
      M.Collapsible.init(elem, {});
   }, [tradeRegistrations]);

   if (!tradeRegistrations) return <></>;

   const journeymanRegistration = tradeRegistrations.find(reg =>
      reg.registrationTypeCode.startsWith(REGISTRATION.CODE_JOURNEYMAN)
   );

   const tradesmanRegistration = tradeRegistrations.find(reg =>
      reg.registrationTypeCode.startsWith(REGISTRATION.CODE_TRADESMAN)
   );

   const certifyingRegistration = tradeRegistrations.find(reg =>
      reg.registrationTypeCode.startsWith(REGISTRATION.CODE_CERTIFIER)
   );

   const suspendedRegistration = tradeRegistrations.find(
      reg =>
         reg.registrationStatusCode ===
         CONTACT.REGISTRATION_SUSPENDED_STATUS_CODE
   );

   const cancelledRegistration = tradeRegistrations.find(
      reg =>
         reg.registrationStatusCode ===
         CONTACT.REGISTRATION_CANCELLED_STATUS_CODE
   );

   let suspendedStatus = '';
   let suspendedRegistrationName = '';

   if (suspendedRegistration) {
      suspendedRegistrationName = suspendedRegistration.registrationDescription;
      suspendedStatus = CONTACT.REGISTRATION_SUSPENDED_STATUS_CODE;
   } else if (cancelledRegistration) {
      suspendedRegistrationName = cancelledRegistration.registrationDescription;
      suspendedStatus = CONTACT.REGISTRATION_CANCELLED_STATUS_CODE;
   }

   const renderRegistrationBox = (
      tradeName,
      previousTradeRegistration,
      tradeRegistration,
      nextTradeRegistration,
      tradePrefix,
   ) => {
      let status = '';
      let code = REGISTRATION.DESCRIPTION_PENDING;
      let showTrophy = false;
      let showStars = false;
      if (!tradeRegistrations && nextTradeRegistration) {
         // user doesn't have a registration for the current level but has achieved the next level.
         // show this as passed this level but with no trophy because it is not active
         status = REGISTRATION.DESCRIPTION_COMPLETED;
         code = REGISTRATION.CODE_COMPLETED;
         showTrophy = true;
      } else if (
         !tradeRegistration &&
         !nextTradeRegistration &&
         !previousTradeRegistration &&
         tradeName !== REGISTRATION.DESCRIPTION_JOURNEYMAN
      ) {
         // doesn't have the current trade or the next trade. Return nothing
      } else if (
         (!tradeRegistration && previousTradeRegistration) ||
         (tradeName === REGISTRATION.DESCRIPTION_JOURNEYMAN &&
            !tradeRegistration)
      ) {
         status = '';
         code = REGISTRATION.DESCRIPTION_PENDING;
      } else if (!previousTradeRegistration && nextTradeRegistration) {
         // user doesn't have a previous registration but has achieved the next level
         // show this as passed this level but with no trophy as it should only show it
         // for the next level
         status = REGISTRATION.DESCRIPTION_COMPLETED;
         code = REGISTRATION.CODE_COMPLETED;
         showTrophy = false;
      } else if (tradeRegistration) {
         // this is the current registration of the user i.e. no next trade registrations
         status = moment(tradeRegistration.registeredDate).format('DD/MM/YYYY');
         code =
            tradeRegistration.registrationStatusCode ===
               CONTACT.REGISTRATION_SUSPENDED_STATUS_CODE ||
            tradeRegistration.registrationStatusCode ===
               CONTACT.REGISTRATION_CANCELLED_STATUS_CODE
               ? REGISTRATION.DESCRIPTION_SUSPENDED
               : REGISTRATION.CODE_COMPLETED;
         showTrophy = !certifyingRegistration;
         showStars = !certifyingRegistration;
      }

      let disciplineImage = 'images/icon-plumber-white.svg';
      if (disciplineCode === REGISTRATION.DISCIPLINE_CODE_DRAINLAYER) {
         disciplineImage = 'images/icon-drainlayer-white.svg';
      } else if (disciplineCode === REGISTRATION.DISCIPLINE_CODE_GASFITTER) {
         disciplineImage = 'images/icon-gasfitter-white.svg';
      }

      return (
         <div className={`${tradeName} ${disciplineCode} trade-box ${code}`}>
            {showStars ? (
               <img
                  alt="Stars"
                  className="trophy-stars"
                  src="images/Stars.png"
               />
            ) : (
               <></>
            )}
            {showTrophy ? (
               <>
                  <img
                     className="trophy-img"
                     alt="Trophy"
                     src="images/Trophy.svg"
                  />
                  <img
                     className="discipline-img"
                     alt="Discipline"
                     src={disciplineImage}
                  />
               </>
            ) : (
               <></>
            )}
            <p className="trade-level">{`${tradePrefix} ${disciplineDescription}`}</p>
            <p className={`trade-status ${code}`}>{status}</p>
         </div>
      );
   };

   return (
      <ul className="trade-collapsible collapsible expandable">
         <li>
            <div className="collapsible-header">
               <div className="row">
                  <div className="col l10 m12 offset-l1 s12 header-column">
                     <h2 className="registration-trade-header">{heading}</h2>
                     <Icon className="remove-icon" iconName="remove" />
                     <Icon className="add-icon" iconName="add" />
                  </div>
               </div>
            </div>
            <div className="collapsible-body">
               <div className="row">
                  <div className="col m10 offset-m1 s12">
                     <div className="row trade-row">
                        {certifyingRegistration ? (
                           <div className="col s10 m10 offset-m1">
                              <p className="trade-subtitle">
                                 Registration is for life. However, if you will
                                 be carrying out restricted work you must also
                                 hold a current practising licence.
                              </p>
                           </div>
                        ) : (
                           <></>
                        )}
                        {tradeRegistrations.length ? (
                           <div className="row">
                              <div className="col m6 offset-l1 s11">
                                 {renderRegistrationBox(
                                    REGISTRATION.DESCRIPTION_JOURNEYMAN,
                                    null,
                                    journeymanRegistration,
                                    tradesmanRegistration,
                                    'Restricted'
                                 )}
                                 {renderRegistrationBox(
                                    REGISTRATION.DESCRIPTION_TRADESMAN,
                                    journeymanRegistration,
                                    tradesmanRegistration,
                                    certifyingRegistration,
                                    ''
                                 )}
                                 {renderRegistrationBox(
                                    REGISTRATION.DESCRIPTION_CERTIFIER,
                                    tradesmanRegistration,
                                    certifyingRegistration,
                                    null,
                                    'Certifying'
                                 )}
                              </div>
                              <div className="col m6 s12 l5 application-section">
                                 <ApplicationSection
                                    disciplineCode={disciplineCode}
                                    statusCode={suspendedStatus}
                                    disciplineName={suspendedRegistrationName}
                                 />
                              </div>
                           </div>
                        ) : (
                           <>
                              <div className="col m8 offset-l2 s12">
                                 <h4 className="invalid-registration-title">
                                    You do not have a registration in this trade
                                 </h4>
                              </div>
                              <div className="col s5">
                                 <ApplicationSection
                                    disciplineCode={disciplineCode}
                                 />
                              </div>
                              <div className="col s2">
                                 <p className="trade-splitter">or</p>
                              </div>
                              <div className="col s5">
                                 <ApplicationSection
                                    disciplineCode={`${disciplineCode}2`}
                                 />
                              </div>
                           </>
                        )}
                     </div>
                  </div>
               </div>
            </div>
         </li>
      </ul>
   );
};

TradeSection.propTypes = {
   heading: string.isRequired,
   disciplineCode: string.isRequired,
   userRegistration: object,
};

const mapStateToProps = state => {
   return {
      userRegistration: state.registration,
   };
};

export default connect(mapStateToProps)(TradeSection);
