import React from 'react';
import { string } from 'prop-types';

export const LicenceTitle = ({ level, discipline }) => {
   return (
      <>
         <p className="level">{level === '' ? <br />: level}</p>
         <p className="trade">{discipline}</p>
      </>
   );
};

LicenceTitle.propTypes = {
   level: string.isRequired,
   discipline: string.isRequired,
};
